<template>
  <svg viewBox="0 0 23 23" :width="size">
    <switch>
      <g>
        <path
          d="M20.5 22.1h-5.6c-1.2 0-2.1-.7-2.1-1.6v-4.3c0-.9.9-1.6 2.1-1.6h5.6c1.2 0 2.1.7 2.1 1.6v4.3c.1.9-.8 1.6-2.1 1.6zm-5.6-6.6c-.6 0-1.1.3-1.1.6v4.3c0 .3.5.6 1.1.6h5.6c.6 0 1.1-.3 1.1-.6v-4.3c0-.3-.5-.6-1.1-.6h-5.6zm-6.7 6.6H2.6c-1.2 0-2.1-1.2-2.1-2.6v-6.6c0-1.4 1-2.6 2.1-2.6h5.6c1.2 0 2.1.5 2.1 1.9v7.3c.1 1.4-.9 2.6-2.1 2.6zM2.6 11.3c-.6 0-1.1.7-1.1 1.5v6.6c0 .9.5 1.6 1.1 1.6h5.6c.6 0 1.1-.7 1.1-1.5v-6.6c0-.8-.5-1.5-1.1-1.5H2.6zm17.9.9h-5.6c-1.2 0-2.1-1.2-2.1-2.6V3c0-1.4 1-2.6 2.1-2.6h5.6c1.2 0 2.1 1.2 2.1 2.6v6.6c.1 1.5-.9 2.6-2.1 2.6zM14.9 1.5c-.6 0-1.1.7-1.1 1.5v6.6c0 .8.5 1.5 1.1 1.5h5.6c.6 0 1.1-.7 1.1-1.5V3c0-.8-.5-1.5-1.1-1.5h-5.6zM8.2 8H2.6C1.4 8 .5 7.3.5 6.4V2.1c0-.9.9-1.6 2.1-1.6h5.6c1.2 0 2.1.7 2.1 1.6v4.3c.1.9-.9 1.6-2.1 1.6zM2.6 1.5c-.6 0-1.1.3-1.1.6v4.3c0 .3.5.6 1.1.6h5.6c.6 0 1.1-.3 1.1-.6V2.1c0-.3-.5-.6-1.1-.6H2.6z"
        />
        <path
          d="M20.5 22.5h-5.6c-1.5 0-2.6-.9-2.6-2.1v-4.3c0-1.2 1.1-2.1 2.6-2.1h5.6c1.5 0 2.6.9 2.6 2.1v4.3c0 1.2-1.1 2.1-2.6 2.1zM14.9 15c-.9 0-1.7.5-1.7 1.2v4.3c0 .7.7 1.2 1.7 1.2h5.6c.9 0 1.7-.5 1.7-1.2v-4.3c0-.7-.7-1.2-1.7-1.2h-5.6zm-6.7 7.5H2.6c-1.4 0-2.6-1.4-2.6-3v-6.6c0-1.7 1.2-3 2.6-3h5.6c1.4 0 2.6.7 2.6 2.4v7.3c0 1.6-1.1 2.9-2.6 2.9zM2.6 10.8c-.9 0-1.7 1-1.7 2.1v6.6c0 1.2.8 2.1 1.7 2.1h5.6c.9 0 1.7-1 1.7-2.1v-7.3c0-1.2-.8-1.5-1.7-1.5H2.6zm17.9 10.7h-5.6c-.9 0-1.6-.5-1.6-1v-4.3c0-.6.7-1 1.6-1h5.6c.9 0 1.6.5 1.6 1v4.3c0 .5-.7 1-1.6 1zm-6.2-1.1c.1.1.3.2.6.2h5.6c.3 0 .6-.1.6-.2v-4.2c-.1-.1-.3-.2-.6-.2h-5.6c-.3 0-.6.1-.6.2v4.2zm-6.1 1.1H2.6c-.9 0-1.6-.9-1.6-2v-6.6c0-1.1.7-2 1.6-2h5.6c.9 0 1.6.9 1.6 2v6.6c0 1.1-.7 2-1.6 2zm-5.6-9.7c-.3 0-.6.5-.6 1.1v6.6c0 .6.3 1.1.6 1.1h5.6c.3 0 .6-.5.6-1.1v-6.6c0-.6-.3-1.1-.6-1.1H2.6zm17.9.9h-5.6c-1.4 0-2.6-.7-2.6-2.4V3c0-1.7 1.2-3 2.6-3h5.6c1.4 0 2.6 1.4 2.6 3v6.6c0 1.7-1.1 3.1-2.6 3.1zM14.9.9c-.9 0-1.7 1-1.7 2.1v6.6c0 1.2.8 2.1 1.7 2.1h5.6c.9 0 1.7-1 1.7-2.1V3c0-1.2-.8-2.1-1.7-2.1h-5.6zm5.6 10.8h-5.6c-.9 0-1.6-.9-1.6-2V3c0-1.1.7-2 1.6-2h5.6c.9 0 1.6.9 1.6 2v6.6c0 1.2-.7 2.1-1.6 2.1zm-5.6-9.8c-.3 0-.6.5-.6 1.1v6.6c0 .6.3 1.1.6 1.1h5.6c.3 0 .6-.5.6-1.1V3c0-.6-.3-1.1-.6-1.1h-5.6zM8.2 8.5H2.6C1.1 8.5 0 7.6 0 6.4V2.1C0 .9 1.1 0 2.6 0h5.6c1.5 0 2.6.9 2.6 2.1v4.3c0 1.2-1.1 2.1-2.6 2.1zM2.6.9C1.7.9.9 1.4.9 2.1v4.3c0 .7.7 1.2 1.7 1.2h5.6c.9 0 1.7-.5 1.7-1.2V2.1c0-.7-.7-1.2-1.7-1.2H2.6zm5.6 6.5H2.6C1.7 7.4 1 7 1 6.4V2.1c0-.6.7-1 1.6-1h5.6c.9 0 1.6.5 1.6 1v4.3c0 .6-.7 1-1.6 1zM1.9 6.3c.1.1.4.2.7.2h5.6c.3 0 .6-.1.6-.2V2.1s-.2-.2-.6-.2H2.6c-.3 0-.6.2-.7.2v4.2z"
        />
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 19
    }
  }
};
</script>
